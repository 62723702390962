<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_profile_details"
      aria-expanded="true"
      aria-controls="kt_account_profile_details"
    >
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Profile Details</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="saveChanges1()"
        :validation-schema="profileDetailsValidator"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9">
          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label required fw-bold fs-6"
              >(nick)name</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8">
              <!--begin::Row-->
              <div class="row">
                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    type="text"
                    name="fname"
                    class="
                      form-control form-control-lg form-control-solid
                      mb-3 mb-lg-0
                    "
                    placeholder="First name"
                    v-model="profileDetails.name"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label fw-bold fs-6">
              <span>Contact Phone</span>
            </label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field
                type="tel"
                name="phone"
                class="form-control form-control-lg form-control-solid"
                placeholder="Phone number"
                v-model="profileDetails.contactPhone"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label fw-bold fs-6">Language</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <!--begin::Input-->
              <Field
                as="select"
                name="language"
                class="form-select form-select-solid form-select-lg"
                v-model="profileDetails.language"
              >
                <option value="id">Bahasa Indonesia - Indonesian</option>
                <option value="msa">Bahasa Melayu - Malay</option>
                <option value="ca">Català - Catalan</option>
                <option value="cs">Čeština - Czech</option>
                <option value="da">Dansk - Danish</option>
                <option value="de">Deutsch - German</option>
                <option value="en">English</option>
                <option value="en-gb">English UK - British English</option>
                <option value="es">Español - Spanish</option>
                <option value="fil">Filipino</option>
                <option value="fr">Français - French</option>
                <option value="ga">Gaeilge - Irish (beta)</option>
                <option value="gl">Galego - Galician (beta)</option>
                <option value="hr">Hrvatski - Croatian</option>
                <option value="it">Italiano - Italian</option>
                <option value="hu">Magyar - Hungarian</option>
                <option value="nl">Nederlands - Dutch</option>
                <option value="no">Norsk - Norwegian</option>
                <option value="pl">Polski - Polish</option>
                <option value="pt">Português - Portuguese</option>
                <option value="ro">Română - Romanian</option>
                <option value="sk">Slovenčina - Slovak</option>
                <option value="fi">Suomi - Finnish</option>
                <option value="sv">Svenska - Swedish</option>
                <option value="vi">Tiếng Việt - Vietnamese</option>
                <option value="tr">Türkçe - Turkish</option>
                <option value="el">Ελληνικά - Greek</option>
                <option value="bg">Български език - Bulgarian</option>
                <option value="ru">Русский - Russian</option>
                <option value="sr">Српски - Serbian</option>
                <option value="uk">Українська мова - Ukrainian</option>
                <option value="he">עִבְרִית - Hebrew</option>
                <option value="ur">اردو - Urdu (beta)</option>
                <option value="ar">العربية - Arabic</option>
                <option value="fa">فارسی - Persian</option>
                <option value="mr">मराठी - Marathi</option>
                <option value="hi">हिन्दी - Hindi</option>
                <option value="bn">বাংলা - Bangla</option>
                <option value="gu">ગુજરાતી - Gujarati</option>
                <option value="ta">தமிழ் - Tamil</option>
                <option value="kn">ಕನ್ನಡ - Kannada</option>
                <option value="th">ภาษาไทย - Thai</option>
                <option value="ko">한국어 - Korean</option>
                <option value="ja">日本語 - Japanese</option>
                <option value="zh-cn">简体中文 - Simplified Chinese</option>
                <option value="zh-tw">繁體中文 - Traditional Chinese</option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="language" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="row mb-6">
            <!--begin::Label-->
            <label class="col-lg-4 col-form-label fw-bold fs-6"
              >Time Zone</label
            >
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field
                as="select"
                name="timezone"
                class="form-select form-select-solid form-select-lg"
                v-model="profileDetails.timezone"
              >
                <option
                  data-bs-offset="-39600"
                  value="International Date Line West"
                >
                  (GMT-11:00) International Date Line West
                </option>
                <option data-bs-offset="-39600" value="Midway Island">
                  (GMT-11:00) Midway Island
                </option>
                <option data-bs-offset="-39600" value="Samoa">
                  (GMT-11:00) Samoa
                </option>
                <option data-bs-offset="-36000" value="Hawaii">
                  (GMT-10:00) Hawaii
                </option>
                <option data-bs-offset="-28800" value="Alaska">
                  (GMT-08:00) Alaska
                </option>
                <option
                  data-bs-offset="-25200"
                  value="Pacific Time (US &amp; Canada)"
                >
                  (GMT-07:00) Pacific Time (US &amp; Canada)
                </option>
                <option data-bs-offset="-25200" value="Tijuana">
                  (GMT-07:00) Tijuana
                </option>
                <option data-bs-offset="-25200" value="Arizona">
                  (GMT-07:00) Arizona
                </option>
                <option
                  data-bs-offset="-21600"
                  value="Mountain Time (US &amp; Canada)"
                >
                  (GMT-06:00) Mountain Time (US &amp; Canada)
                </option>
                <option data-bs-offset="-21600" value="Chihuahua">
                  (GMT-06:00) Chihuahua
                </option>
                <option data-bs-offset="-21600" value="Mazatlan">
                  (GMT-06:00) Mazatlan
                </option>
                <option data-bs-offset="-21600" value="Saskatchewan">
                  (GMT-06:00) Saskatchewan
                </option>
                <option data-bs-offset="-21600" value="Central America">
                  (GMT-06:00) Central America
                </option>
                <option
                  data-bs-offset="-18000"
                  value="Central Time (US &amp; Canada)"
                >
                  (GMT-05:00) Central Time (US &amp; Canada)
                </option>
                <option data-bs-offset="-18000" value="Guadalajara">
                  (GMT-05:00) Guadalajara
                </option>
                <option data-bs-offset="-18000" value="Mexico City">
                  (GMT-05:00) Mexico City
                </option>
                <option data-bs-offset="-18000" value="Monterrey">
                  (GMT-05:00) Monterrey
                </option>
                <option data-bs-offset="-18000" value="Bogota">
                  (GMT-05:00) Bogota
                </option>
                <option data-bs-offset="-18000" value="Lima">
                  (GMT-05:00) Lima
                </option>
                <option data-bs-offset="-18000" value="Quito">
                  (GMT-05:00) Quito
                </option>
                <option
                  data-bs-offset="-14400"
                  value="Eastern Time (US &amp; Canada)"
                >
                  (GMT-04:00) Eastern Time (US &amp; Canada)
                </option>
                <option data-bs-offset="-14400" value="Indiana (East)">
                  (GMT-04:00) Indiana (East)
                </option>
                <option data-bs-offset="-14400" value="Caracas">
                  (GMT-04:00) Caracas
                </option>
                <option data-bs-offset="-14400" value="La Paz">
                  (GMT-04:00) La Paz
                </option>
                <option data-bs-offset="-14400" value="Georgetown">
                  (GMT-04:00) Georgetown
                </option>
                <option data-bs-offset="-10800" value="Atlantic Time (Canada)">
                  (GMT-03:00) Atlantic Time (Canada)
                </option>
                <option data-bs-offset="-10800" value="Santiago">
                  (GMT-03:00) Santiago
                </option>
                <option data-bs-offset="-10800" value="Brasilia">
                  (GMT-03:00) Brasilia
                </option>
                <option data-bs-offset="-10800" value="Buenos Aires">
                  (GMT-03:00) Buenos Aires
                </option>
                <option data-bs-offset="-9000" value="Newfoundland">
                  (GMT-02:30) Newfoundland
                </option>
                <option data-bs-offset="-7200" value="Greenland">
                  (GMT-02:00) Greenland
                </option>
                <option data-bs-offset="-7200" value="Mid-Atlantic">
                  (GMT-02:00) Mid-Atlantic
                </option>
                <option data-bs-offset="-3600" value="Cape Verde Is.">
                  (GMT-01:00) Cape Verde Is.
                </option>
                <option data-bs-offset="0" value="Azores">(GMT) Azores</option>
                <option data-bs-offset="0" value="Monrovia">
                  (GMT) Monrovia
                </option>
                <option data-bs-offset="0" value="UTC">(GMT) UTC</option>
                <option data-bs-offset="3600" value="Dublin">
                  (GMT+01:00) Dublin
                </option>
                <option data-bs-offset="3600" value="Edinburgh">
                  (GMT+01:00) Edinburgh
                </option>
                <option data-bs-offset="3600" value="Lisbon">
                  (GMT+01:00) Lisbon
                </option>
                <option data-bs-offset="3600" value="London">
                  (GMT+01:00) London
                </option>
                <option data-bs-offset="3600" value="Casablanca">
                  (GMT+01:00) Casablanca
                </option>
                <option data-bs-offset="3600" value="West Central Africa">
                  (GMT+01:00) West Central Africa
                </option>
                <option data-bs-offset="7200" value="Belgrade">
                  (GMT+02:00) Belgrade
                </option>
                <option data-bs-offset="7200" value="Bratislava">
                  (GMT+02:00) Bratislava
                </option>
                <option data-bs-offset="7200" value="Budapest">
                  (GMT+02:00) Budapest
                </option>
                <option data-bs-offset="7200" value="Ljubljana">
                  (GMT+02:00) Ljubljana
                </option>
                <option data-bs-offset="7200" value="Prague">
                  (GMT+02:00) Prague
                </option>
                <option data-bs-offset="7200" value="Sarajevo">
                  (GMT+02:00) Sarajevo
                </option>
                <option data-bs-offset="7200" value="Skopje">
                  (GMT+02:00) Skopje
                </option>
                <option data-bs-offset="7200" value="Warsaw">
                  (GMT+02:00) Warsaw
                </option>
                <option data-bs-offset="7200" value="Zagreb">
                  (GMT+02:00) Zagreb
                </option>
                <option data-bs-offset="7200" value="Brussels">
                  (GMT+02:00) Brussels
                </option>
                <option data-bs-offset="7200" value="Copenhagen">
                  (GMT+02:00) Copenhagen
                </option>
                <option data-bs-offset="7200" value="Madrid">
                  (GMT+02:00) Madrid
                </option>
                <option data-bs-offset="7200" value="Paris">
                  (GMT+02:00) Paris
                </option>
                <option data-bs-offset="7200" value="Amsterdam">
                  (GMT+02:00) Amsterdam
                </option>
                <option data-bs-offset="7200" value="Berlin">
                  (GMT+02:00) Berlin
                </option>
                <option data-bs-offset="7200" value="Bern">
                  (GMT+02:00) Bern
                </option>
                <option data-bs-offset="7200" value="Rome">
                  (GMT+02:00) Rome
                </option>
                <option data-bs-offset="7200" value="Stockholm">
                  (GMT+02:00) Stockholm
                </option>
                <option data-bs-offset="7200" value="Vienna">
                  (GMT+02:00) Vienna
                </option>
                <option data-bs-offset="7200" value="Cairo">
                  (GMT+02:00) Cairo
                </option>
                <option data-bs-offset="7200" value="Harare">
                  (GMT+02:00) Harare
                </option>
                <option data-bs-offset="7200" value="Pretoria">
                  (GMT+02:00) Pretoria
                </option>
                <option data-bs-offset="10800" value="Bucharest">
                  (GMT+03:00) Bucharest
                </option>
                <option data-bs-offset="10800" value="Helsinki">
                  (GMT+03:00) Helsinki
                </option>
                <option data-bs-offset="10800" value="Kiev">
                  (GMT+03:00) Kiev
                </option>
                <option data-bs-offset="10800" value="Kyiv">
                  (GMT+03:00) Kyiv
                </option>
                <option data-bs-offset="10800" value="Riga">
                  (GMT+03:00) Riga
                </option>
                <option data-bs-offset="10800" value="Sofia">
                  (GMT+03:00) Sofia
                </option>
                <option data-bs-offset="10800" value="Tallinn">
                  (GMT+03:00) Tallinn
                </option>
                <option data-bs-offset="10800" value="Vilnius">
                  (GMT+03:00) Vilnius
                </option>
                <option data-bs-offset="10800" value="Athens">
                  (GMT+03:00) Athens
                </option>
                <option data-bs-offset="10800" value="Istanbul">
                  (GMT+03:00) Istanbul
                </option>
                <option data-bs-offset="10800" value="Minsk">
                  (GMT+03:00) Minsk
                </option>
                <option data-bs-offset="10800" value="Jerusalem">
                  (GMT+03:00) Jerusalem
                </option>
                <option data-bs-offset="10800" value="Moscow">
                  (GMT+03:00) Moscow
                </option>
                <option data-bs-offset="10800" value="St. Petersburg">
                  (GMT+03:00) St. Petersburg
                </option>
                <option data-bs-offset="10800" value="Volgograd">
                  (GMT+03:00) Volgograd
                </option>
                <option data-bs-offset="10800" value="Kuwait">
                  (GMT+03:00) Kuwait
                </option>
                <option data-bs-offset="10800" value="Riyadh">
                  (GMT+03:00) Riyadh
                </option>
                <option data-bs-offset="10800" value="Nairobi">
                  (GMT+03:00) Nairobi
                </option>
                <option data-bs-offset="10800" value="Baghdad">
                  (GMT+03:00) Baghdad
                </option>
                <option data-bs-offset="14400" value="Abu Dhabi">
                  (GMT+04:00) Abu Dhabi
                </option>
                <option data-bs-offset="14400" value="Muscat">
                  (GMT+04:00) Muscat
                </option>
                <option data-bs-offset="14400" value="Baku">
                  (GMT+04:00) Baku
                </option>
                <option data-bs-offset="14400" value="Tbilisi">
                  (GMT+04:00) Tbilisi
                </option>
                <option data-bs-offset="14400" value="Yerevan">
                  (GMT+04:00) Yerevan
                </option>
                <option data-bs-offset="16200" value="Tehran">
                  (GMT+04:30) Tehran
                </option>
                <option data-bs-offset="16200" value="Kabul">
                  (GMT+04:30) Kabul
                </option>
                <option data-bs-offset="18000" value="Ekaterinburg">
                  (GMT+05:00) Ekaterinburg
                </option>
                <option data-bs-offset="18000" value="Islamabad">
                  (GMT+05:00) Islamabad
                </option>
                <option data-bs-offset="18000" value="Karachi">
                  (GMT+05:00) Karachi
                </option>
                <option data-bs-offset="18000" value="Tashkent">
                  (GMT+05:00) Tashkent
                </option>
                <option data-bs-offset="19800" value="Chennai">
                  (GMT+05:30) Chennai
                </option>
                <option data-bs-offset="19800" value="Kolkata">
                  (GMT+05:30) Kolkata
                </option>
                <option data-bs-offset="19800" value="Mumbai">
                  (GMT+05:30) Mumbai
                </option>
                <option data-bs-offset="19800" value="New Delhi">
                  (GMT+05:30) New Delhi
                </option>
                <option data-bs-offset="19800" value="Sri Jayawardenepura">
                  (GMT+05:30) Sri Jayawardenepura
                </option>
                <option data-bs-offset="20700" value="Kathmandu">
                  (GMT+05:45) Kathmandu
                </option>
                <option data-bs-offset="21600" value="Astana">
                  (GMT+06:00) Astana
                </option>
                <option data-bs-offset="21600" value="Dhaka">
                  (GMT+06:00) Dhaka
                </option>
                <option data-bs-offset="21600" value="Almaty">
                  (GMT+06:00) Almaty
                </option>
                <option data-bs-offset="21600" value="Urumqi">
                  (GMT+06:00) Urumqi
                </option>
                <option data-bs-offset="23400" value="Rangoon">
                  (GMT+06:30) Rangoon
                </option>
                <option data-bs-offset="25200" value="Novosibirsk">
                  (GMT+07:00) Novosibirsk
                </option>
                <option data-bs-offset="25200" value="Bangkok">
                  (GMT+07:00) Bangkok
                </option>
                <option data-bs-offset="25200" value="Hanoi">
                  (GMT+07:00) Hanoi
                </option>
                <option data-bs-offset="25200" value="Jakarta">
                  (GMT+07:00) Jakarta
                </option>
                <option data-bs-offset="25200" value="Krasnoyarsk">
                  (GMT+07:00) Krasnoyarsk
                </option>
                <option data-bs-offset="28800" value="Beijing">
                  (GMT+08:00) Beijing
                </option>
                <option data-bs-offset="28800" value="Chongqing">
                  (GMT+08:00) Chongqing
                </option>
                <option data-bs-offset="28800" value="Hong Kong">
                  (GMT+08:00) Hong Kong
                </option>
                <option data-bs-offset="28800" value="Kuala Lumpur">
                  (GMT+08:00) Kuala Lumpur
                </option>
                <option data-bs-offset="28800" value="Singapore">
                  (GMT+08:00) Singapore
                </option>
                <option data-bs-offset="28800" value="Taipei">
                  (GMT+08:00) Taipei
                </option>
                <option data-bs-offset="28800" value="Perth">
                  (GMT+08:00) Perth
                </option>
                <option data-bs-offset="28800" value="Irkutsk">
                  (GMT+08:00) Irkutsk
                </option>
                <option data-bs-offset="28800" value="Ulaan Bataar">
                  (GMT+08:00) Ulaan Bataar
                </option>
                <option data-bs-offset="32400" value="Seoul">
                  (GMT+09:00) Seoul
                </option>
                <option data-bs-offset="32400" value="Osaka">
                  (GMT+09:00) Osaka
                </option>
                <option data-bs-offset="32400" value="Sapporo">
                  (GMT+09:00) Sapporo
                </option>
                <option data-bs-offset="32400" value="Tokyo">
                  (GMT+09:00) Tokyo
                </option>
                <option data-bs-offset="32400" value="Yakutsk">
                  (GMT+09:00) Yakutsk
                </option>
                <option data-bs-offset="34200" value="Darwin">
                  (GMT+09:30) Darwin
                </option>
                <option data-bs-offset="34200" value="Adelaide">
                  (GMT+09:30) Adelaide
                </option>
                <option data-bs-offset="36000" value="Canberra">
                  (GMT+10:00) Canberra
                </option>
                <option data-bs-offset="36000" value="Melbourne">
                  (GMT+10:00) Melbourne
                </option>
                <option data-bs-offset="36000" value="Sydney">
                  (GMT+10:00) Sydney
                </option>
                <option data-bs-offset="36000" value="Brisbane">
                  (GMT+10:00) Brisbane
                </option>
                <option data-bs-offset="36000" value="Hobart">
                  (GMT+10:00) Hobart
                </option>
                <option data-bs-offset="36000" value="Vladivostok">
                  (GMT+10:00) Vladivostok
                </option>
                <option data-bs-offset="36000" value="Guam">
                  (GMT+10:00) Guam
                </option>
                <option data-bs-offset="36000" value="Port Moresby">
                  (GMT+10:00) Port Moresby
                </option>
                <option data-bs-offset="36000" value="Solomon Is.">
                  (GMT+10:00) Solomon Is.
                </option>
                <option data-bs-offset="39600" value="Magadan">
                  (GMT+11:00) Magadan
                </option>
                <option data-bs-offset="39600" value="New Caledonia">
                  (GMT+11:00) New Caledonia
                </option>
                <option data-bs-offset="43200" value="Fiji">
                  (GMT+12:00) Fiji
                </option>
                <option data-bs-offset="43200" value="Kamchatka">
                  (GMT+12:00) Kamchatka
                </option>
                <option data-bs-offset="43200" value="Marshall Is.">
                  (GMT+12:00) Marshall Is.
                </option>
                <option data-bs-offset="43200" value="Auckland">
                  (GMT+12:00) Auckland
                </option>
                <option data-bs-offset="43200" value="Wellington">
                  (GMT+12:00) Wellington
                </option>
                <option data-bs-offset="46800" value="Nuku'alofa">
                  (GMT+13:00) Nuku'alofa
                </option>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="timezone" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->
        </div>
        <!--end::Card body-->

        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="reset"
            class="btn btn-white btn-active-light-primary me-2"
          >
            Discard
          </button>

          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButtonProfile"
            class="btn btn-primary"
            @click="saveChanges1"
          >
            <span class="indicator-label"> Save Changes </span>
            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->

  <!--begin::Sign-in Method-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div
      class="card-header border-0 cursor-pointer"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">Sign-in Method</h3>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9">
        <!--begin::Email Address-->
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div id="kt_signin_email" :class="{ 'd-none': emailFormDisplay }">
            <div class="fs-4 fw-boldest mb-1">Email Address</div>
            <div class="fs-6 fw-bold text-gray-600">
              Not working yet, support@cryptocopytrade.io
            </div>
          </div>

          <!--          <div-->
          <!--            id="kt_signin_email_edit"-->
          <!--            :class="{ 'd-none': !emailFormDisplay }"-->
          <!--            class="flex-row-fluid"-->
          <!--          >-->
          <!--begin::Form-->
          <!--            <Form-->
          <!--              id="kt_signin_change_email"-->
          <!--              class="form"-->
          <!--              novalidate="novalidate"-->
          <!--              @submit="updateEmail()"-->
          <!--              :validation-schema="changeEmail"-->
          <!--            >-->
          <!--              <div class="row mb-6">-->
          <!--                <div class="col-lg-6 mb-4 mb-lg-0">-->
          <!--                  <div class="fv-row mb-0">-->
          <!--                    <label-->
          <!--                      for="emailaddress"-->
          <!--                      class="form-label fs-6 fw-bolder mb-3"-->
          <!--                      >Enter New Email Address</label-->
          <!--                    >-->
          <!--                    <Field-->
          <!--                      type="email"-->
          <!--                      class="-->
          <!--                        form-control form-control-lg form-control-solid-->
          <!--                        fw-bold-->
          <!--                        fs-6-->
          <!--                      "-->
          <!--                      id="emailaddress"-->
          <!--                      placeholder="Email Address"-->
          <!--                      name="emailaddress"-->
          <!--                      value="support@keenthemes.com"-->
          <!--                    />-->
          <!--                    <div class="fv-plugins-message-container">-->
          <!--                      <div class="fv-help-block">-->
          <!--                        <ErrorMessage name="emailaddress" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="col-lg-6">-->
          <!--                  <div class="fv-row mb-0">-->
          <!--                    <label-->
          <!--                      for="confirmemailpassword"-->
          <!--                      class="form-label fs-6 fw-bolder mb-3"-->
          <!--                      >Confirm Password</label-->
          <!--                    >-->
          <!--                    <Field-->
          <!--                      type="password"-->
          <!--                      class="-->
          <!--                        form-control form-control-lg form-control-solid-->
          <!--                        fw-bold-->
          <!--                        fs-6-->
          <!--                      "-->
          <!--                      name="confirmemailpassword"-->
          <!--                      id="confirmemailpassword"-->
          <!--                    />-->
          <!--                    <div class="fv-plugins-message-container">-->
          <!--                      <div class="fv-help-block">-->
          <!--                        <ErrorMessage name="confirmemailpassword" />-->
          <!--                      </div>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="d-flex">-->
          <!--                <button-->
          <!--                  id="kt_signin_submit"-->
          <!--                  type="submit"-->
          <!--                  ref="updateEmailButton"-->
          <!--                  class="btn btn-primary me-2 px-6"-->
          <!--                >-->
          <!--                  <span class="indicator-label"> Update Email </span>-->
          <!--                  <span class="indicator-progress">-->
          <!--                    Please wait...-->
          <!--                    <span-->
          <!--                      class="spinner-border spinner-border-sm align-middle ms-2"-->
          <!--                    ></span>-->
          <!--                  </span>-->
          <!--                </button>-->
          <!--                <button-->
          <!--                  id="kt_signin_cancel"-->
          <!--                  type="button"-->
          <!--                  class="btn btn-color-gray-400 btn-active-light-primary px-6"-->
          <!--                  @click="emailFormDisplay = !emailFormDisplay"-->
          <!--                >-->
          <!--                  Cancel-->
          <!--                </button>-->
          <!--              </div>-->
          <!--            </Form>-->
          <!--end::Form-->
          <!--          </div>-->
          <!--          <div-->
          <!--            id="kt_signin_email_button"-->
          <!--            :class="{ 'd-none': emailFormDisplay }"-->
          <!--            class="ms-auto"-->
          <!--          >-->
          <!--            <button-->
          <!--              class="btn btn-light fw-boldest px-6"-->
          <!--              @click="emailFormDisplay = !emailFormDisplay"-->
          <!--            >-->
          <!--              Change Email-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
        <!--end::Email Address-->

        <!--begin::Password-->
        <div class="d-flex flex-wrap align-items-center mb-8">
          <div
            id="kt_signin_password"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <div class="fs-4 fw-boldest mb-1">Password</div>
            <div class="fs-6 fw-bold text-gray-600">************</div>
          </div>
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <div class="fs-6 fw-bold text-gray-600 mb-4">
              Password must be at least 8 character and contain symbols
            </div>

            <!--begin::Form-->
            <Form
              id="kt_signin_change_password"
              class="form"
              novalidate="novalidate"
              @submit="updatePassword()"
              :validation-schema="changePassword"
            >
              <div class="row mb-6">
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="currentpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >Current Password</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="currentpassword"
                      id="currentpassword"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="currentpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="newpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >New Password</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="newpassword"
                      id="newpassword"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="newpassword" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label
                      for="confirmpassword"
                      class="form-label fs-6 fw-bolder mb-3"
                      >Confirm New Password</label
                    >
                    <Field
                      type="password"
                      class="
                        form-control form-control-lg form-control-solid
                        fw-bold
                        fs-6
                      "
                      name="confirmpassword"
                      id="confirmpassword"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirmpassword" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_password_submit"
                  type="submit"
                  ref="updatePasswordButton"
                  class="btn btn-primary me-2 px-6"
                >
                  <span class="indicator-label"> Update Password </span>
                  <span class="indicator-progress">
                    Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <button
                  id="kt_password_cancel"
                  type="button"
                  @click="passwordFormDisplay = !passwordFormDisplay"
                  class="btn btn-color-gray-400 btn-active-light-primary px-6"
                >
                  Cancel
                </button>
              </div>
            </Form>
            <!--end::Form-->
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="passwordFormDisplay = !passwordFormDisplay"
              class="btn btn-light fw-boldest"
            >
              Reset Password
            </button>
          </div>
        </div>
        <!--end::Password-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface ProfileDetails {
  name: string;
  contactPhone: string;
  language: string;
  timezone: string;
}

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const submitButtonProfile = ref<HTMLElement | null>(null);
    const submitButton2 = ref<HTMLElement | null>(null);
    const submitButton3 = ref<HTMLElement | null>(null);
    const submitButton4 = ref<HTMLElement | null>(null);
    const submitButton5 = ref<HTMLElement | null>(null);
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const profileDetailsValidator = Yup.object().shape({
      name: Yup.string().required().label("First name"),
      phone: Yup.string().required().label("Contact phone"),
      language: Yup.string().required().label("Language"),
      timezone: Yup.string().required().label("Timezone"),
    });

    const changeEmail = Yup.object().shape({
      emailaddress: Yup.string().required().email().label("Email"),
      confirmemailpassword: Yup.string().required().label("Password"),
    });

    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(4).required().label("Password"),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const profileDetails = ref<ProfileDetails>({
      name: "",
      contactPhone: "",
      language: "msa",
      timezone: "Kuala Lumpur",
    });

    store.dispatch(Actions.GET_PROFILE).then((data) => {
      profileDetails.value.name = data.data.nickname;
      profileDetails.value.contactPhone = data.data.contactPhone;
      profileDetails.value.language = data.data.language;
      profileDetails.value.timezone = data.data.timeZone;
    });

    const saveChanges1 = async () => {
      if (submitButtonProfile.value) {
        // Activate indicator
        submitButtonProfile.value.setAttribute("data-kt-indicator", "on");
        await store.dispatch(Actions.UPDATE_PROFILE, profileDetails.value);
        submitButtonProfile.value?.removeAttribute("data-kt-indicator");
      }
    };

    const saveChanges2 = () => {
      if (submitButton2.value) {
        // Activate indicator
        submitButton2.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton2.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const saveChanges3 = () => {
      if (submitButton3.value) {
        // Activate indicator
        submitButton3.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton3.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const saveChanges4 = () => {
      if (submitButton4.value) {
        // Activate indicator
        submitButton4.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton4.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };

    const deactivateAccount = () => {
      if (submitButton5.value) {
        // Activate indicator
        submitButton5.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton5.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Email is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            emailFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    const updateEmail = () => {
      if (updateEmailButton.value) {
        // Activate indicator
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updateEmailButton.value?.removeAttribute("data-kt-indicator");

          emailFormDisplay.value = false;
        }, 2000);
      }
    };

    const updatePassword = () => {
      if (updatePasswordButton.value) {
        // Activate indicator
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          updatePasswordButton.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Password is successfully changed!",
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          }).then(() => {
            passwordFormDisplay.value = false;
          });
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    return {
      submitButtonProfile,
      submitButton2,
      submitButton3,
      submitButton4,
      submitButton5,
      saveChanges1,
      saveChanges2,
      saveChanges3,
      saveChanges4,
      deactivateAccount,
      profileDetails,
      emailFormDisplay,
      passwordFormDisplay,
      profileDetailsValidator,
      changeEmail,
      changePassword,
      updateEmailButton,
      updatePasswordButton,
      updateEmail,
      updatePassword,
    };
  },
});
</script>
